.conversation-list-item {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
  border: 1px solid #eeeef1;
  border-right: none;
}

.card-item-box {
  display: flex;
  flex-direction: column;
  padding: 25px 22px 12px 22px;
  border-right: none;
}

.conversation-list-item:hover {
  background: #eeeef1;
  cursor: pointer;
}

.conversation-list-item.active {
  background: #eeeef1;
}

@media (min-width: 750px) {
  .menu-items {
    position: absolute;
    z-index: 3;
  }
}

@media (max-width: 750px) {
  .menu-items {
    position: absolute;
    z-index: 3;
  }
}

.conversation-list-avatar-container{
  flex: 0 0 auto;
}

.conversation-list-item-name{
  color: rgba(0, 0, 0, 0.61);
  font-size: 16px;
  font-weight: 900;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}

.conversation-info{
  padding-left: 15px;
  flex: 1;
  min-width: 0;
}

.conversation-photo {
  font-family: Asap, Roboto;
  width: 70px;
  height: 60px;
  border-radius: 50%;
  font-weight: 500;
  object-fit: cover;
  opacity: 0.9;
  top: 5px
}

.conversation-photo span{
  font-size: 20px;
}

.conversation-title {
  overflow: hidden;
  margin-bottom: 4px;
}

.conversation-snippet {
  font-size: 13px;
  font-weight: 700;
  width: 261px;
}

.conversation-snippet p{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.conversation-list-item .custom-tags {
 margin-right: 11px !important;
}
.custom-tags {
  overflow: hidden;
  border-radius: 140px;
  margin-right: 0.25em;
  margin-left: 0;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  max-width: fit-content;
  text-overflow: ellipsis;
  padding: 3.5px 5.6px 3.5px 5.6px;
  background-color: rgb(117, 145, 203);
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  opacity: 0.9;
}

.more-tags {
  color: #00bcd4;
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

.right{
  position: absolute;
  right: 5px
}

.full-time{
  padding: 0px 0px 0px 0px;
  font-size: 13px;
  font-weight: bold;
}

.more-tag {
  position: relative;
  left: 12px;
  top: 10px;
}

.p-badge.p-badge-info{
  font-size: 10.5px;
  font-style: normal;
  line-height: 21px;
}

@media (max-width: 750px) {
  .conversation-list-item-name  {
    font-size: 15px;
   }
   .conversation-snippet {
    font-size: 13px;
    font-weight: 700;
    width: 190px;
  }
}

.conversation-list-item .user-group-dropdown {
  z-index: 4;
  position: fixed;
}

.conversation-list-item .user-group-cancel-btn{
  position: fixed;
  z-index: 4;
}

@media (max-width: 750px) {
  .conversation-list-item .user-group-dropdown{
    left: 14em !important;
    margin-top: 1em !important;
  }
  .conversation-list-item .user-group-cancel-btn{
    left: 19em !important;
    margin-top: 0.5em !important;
  }
}

.conversation-list-item .user-group-cancel-btn-icon{
  color: #0436A0 !important;
}

.transferred-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0.2rem;
}
