.toolbar {
  display: flex;
  align-items: center;
  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;
  position: relative;
  top: 0px;
}

@supports (backdrop-filter: blur(20px)) {
  .toolbar {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(1px);
  }
}

.toolbar-title {
  font-size: 18px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.61);
  font-style: normal;
}

@media (max-width: 1000px) {
  .toolbar-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 125px;
  }
}

@media (max-width: 600px) {
  .toolbar-title {
    font-size: 15px;
    font-weight: 700 !important;
    color: rgba(0, 0, 0, 0.61);
    line-height: 18px;
    max-width: 145px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media (max-width: 350px) {
  .toolbar-title {
    font-size: 14px;
    max-width: 105px;
  }
}

@media (max-width: 328px) {
  .toolbar-title {
    font-size: 13px;
    max-width: 94px;
  }
}

.center-items{
  flex: 1;
  padding: 0.75em;
  display: flex;
  flex-flow: wrap;
}

.right-items-title{
  display: flex;
  flex-flow: wrap;
  gap: 20px;
  margin-right: 30px;
}

@media (max-width: 600px) {
  .right-items-title {
    display: flex;
    align-items: center;
    gap: 1px;
    margin-right: 0px;
    padding: 0.75em;
  }
  .right-items-title .p-link {
    display: flex;
  }

  .right-items-title .p-link-send {
    display: flex;
  }
}

@media (max-width: 375px) {
  .right-items-title {
    padding: 0.3em 0.2em;
  }
}

.right-items{
  flex: 1;
  padding: 0.75em;
  display: flex;
  flex-flow: wrap;
}

.left-items{
  left: 10px;
}

.left-items-title{
  flex: 1;
  padding: 1.5em;
  display: flex;
  flex-flow: wrap;
}

.left-items-title .conversation-photo{
  margin-right: 10px;
}

@media (max-width: 600px) {
  .messenger-toolbar .left-items-title {
    display: flex;
    align-items: center;
    gap: 0px;
    margin-right: 0px;
    padding: 0.3em 0.2em;
  }
  .left-items-title .conversation-photo{
    margin-right: 8px;
  }
}

.toolbar-justify-items > .left-items {
  margin: 0.5em;
  justify-content: space-evenly;
}
.toolbar-justify-items > .right-items {
  margin: 0.5em;
  justify-content: space-evenly;
}

.toolbar-justify-items > .center-items {
  margin: 0.25em;
  justify-content: space-around;
}

.right-items {
  flex-direction: row-reverse;
  align-items: center;
}

.right-items .toolbar-button {
  margin-left: 15px;
}

.left-items .toolbar-button:last-child,
.right-items .toolbar-button:last-child {
  margin: 1;
}

@media (max-width: 900px) {
  .global-is-mobile-hidden {
    gap: auto;
  }
}