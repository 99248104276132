.conversation-info-forward {
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    max-width: 350px;
}

.conversation-photo-forward {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    align-self: flex-start;
}

.conversation-title-forward {
    font-size: 15px;
    font-weight: bold;
    text-transform: capitalize;
    margin-top: 0.7em !important;
    margin-left: 3em;
}

.field-footer {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    bottom: 1.5rem;
    position: absolute;
    width: 90%;
    height: 1rem;
    border-top: 1px solid #e0e0e000;
    background-color: #ffffff00;
}

.modal-content-wrapper {
    min-height: 20vh;
    /* Altura minima */
    max-height: 50vh;
    /* Altura máxima */
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 1.5rem;
}

@media (max-width: 600px) {

    .field-footer {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: end;
        bottom: 1.5rem;
        position: absolute;
        width: 85%;
        height: 1rem;
        border-top: 1px solid #e0e0e000;
        background-color: #ffffff00;
    }

    .modal-content-wrapper {
        min-height: 20vh;
        /* Altura mínima */
        max-height: 50vh;
        /* Altura máxima */
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 1.5rem;

    }

    .modal-scrollable {
        overflow-y: auto;
        height: 40vh;
        overflow-x: hidden;
    }

    .conversation-photo-forward {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 0px;
        align-self: flex-start;
    }

    .conversation-title-forward {
        font-size: 15px;
        font-weight: bold;
        text-transform: capitalize;
        margin-top: 0.7em !important;
        margin-left: 1.5rem;
    }

    .conversation-info-forward {
        text-overflow: ellipsis;
        white-space: normal;
        overflow: hidden;
        max-width: 150px;
    }
}

.modal-content-wrapper {
    min-height: 20vh;
    /* Altura minima */
    max-height: 50vh;
    /* Altura máxima */
    overflow-y: auto;
    overflow-x: hidden;

}
