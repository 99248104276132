.message-note-modal .p-dialog-header {
  background-color: #ffffff;
  opacity: 1;
}

.message-note-modal .p-dialog-content {
  grid-row-start: 1;
  grid-row-end: span 2;
  background: url("../../../assets/images/whatsapp-background.png"), #e7e7e7 70%;
  height: 85.5vh;
}

@media (min-height: 750px) {
  .message-note-modal .p-dialog-content {
    height: 89vh;
  }
}
@media (max-height: 750px) {
  .message-note-modal .p-dialog-content {
    height: 82vh;
  }
}

.message-note-modal-toolbar {
  background-color: #ffffff;
  border-color: #ffffff;
}

.message-note-modal .p-dialog-footer {
  padding-bottom: 1%;
  padding-top: 1%;
  background-color: #e7e7e7;
}
